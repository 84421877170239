import ClockIcon from "virtual:icons/lucide/clock";
import CrownIcon from "virtual:icons/lucide/crown";
import FlameIcon from "virtual:icons/lucide/flame";
import InfoIcon from "virtual:icons/lucide/info";
import MessageCircleIcon from "virtual:icons/lucide/message-circle";
import PersonStanding from "virtual:icons/lucide/person-standing";
import XIcon from "virtual:icons/lucide/x";
import { type Character, CharacterStatus } from "@prisma/client";
import { Slot, type SlotProps } from "@radix-ui/react-slot";
import { useTranslation } from "react-i18next";
import { useRequestInfo } from "#app/hooks/use-request-info";
import { cx } from "#app/utils/cva.ts";
import { timeAgo } from "#app/utils/time-ago";

type CharacterCardProps = SlotProps & {
	asChild?: boolean;
	character: Pick<
		Character,
		"avatar" | "description" | "id" | "isNsfw" | "isDeluxe" | "name" | "age" | "characterSpeakform"
	> & {
		creator?: {
			username: string;
		};
		updatedAt?: string;
		nMessages?: number;
		status?: CharacterStatus;
	};
	blurOnRejected?: boolean;
};

export const CharacterCard = ({ character, asChild, blurOnRejected, ...props }: CharacterCardProps) => {
	const requestInfo = useRequestInfo();
	const { t } = useTranslation("common");
	const Comp = asChild ? Slot : "div";
	const showNsfwWarning = character.isNsfw && !requestInfo.userPrefs.nsfw;

	return (
		<Comp
			{...props}
			className={cx(
				"group md:hover:-translate-y-1 relative col-span-3 flex h-full min-h-[400px] cursor-pointer flex-col gap-4 overflow-hidden rounded-xl bg-indigo-500/5 transition-transform duration-300 after:absolute after:top-0 after:right-0 after:z-20 hover:after:block after:hidden after:size-full md:hover:scale-105 after:bg-[radial-gradient(rgba(0,0,0,0.0)65%,rgba(255,255,255,1))] dark:bg-zinc-900/60 after:opacity-20 dark:hover:shadow-xl dark:shadow-black hover:shadow-lg hover:brightness-110 after:transition-all after:content-['']",
				character.isNsfw
					? "border-red-400/60 dark:border-red-500/40 dark:hover:border-red-500/70 hover:border-red-400/80"
					: character.isDeluxe
						? "border-orange-200 dark:border-amber-400/40 dark:hover:border-amber-400/60 hover:border-amber-300"
						: "border-gray-200 dark:border-zinc-700/75 dark:hover:border-zinc-600/90 hover:border-gray-300/90",
				props.className,
			)}
		>
			{/* character avatar */}
			<div className="relative isolate aspect-square size-full overflow-hidden">
				<div className="absolute top-2 left-2 z-40 flex flex-col items-start justify-start gap-y-2">
					{character.isDeluxe ? (
						<div className="flex overflow-hidden rounded-sm bg-gradient-to-r from-amber-400 to-amber-500 font-semibold text-xs shadow-sm ring-1 ring-amber-400">
							<p className="inline-flex items-center gap-1 py-1 pr-2 pl-1 text-white">
								<CrownIcon className="size-3.5" />
								<span>{t("deluxe")}</span>
							</p>
						</div>
					) : null}
					{character.isNsfw ? (
						<div className="flex overflow-hidden rounded-sm bg-gradient-to-r from-red-400 to-red-500 font-semibold text-xs shadow-sm ring-1 ring-red-400">
							<p className="inline-flex items-center gap-1 py-1 pr-2 pl-1 text-white">
								<FlameIcon className="size-3.5" />
								<span>+18</span>
							</p>
						</div>
					) : null}
					{character.characterSpeakform ? (
						<div className="flex overflow-hidden rounded-sm bg-gradient-to-r from-green-400 to-green-500 font-semibold text-xs shadow-sm ring-1 ring-green-400">
							<p className="inline-flex items-center gap-1 py-1 pr-2 pl-1 text-white">
								<PersonStanding className="size-3.5" />
								<span>{character.characterSpeakform === "first person" ? "1st P" : "3rd P"}</span>
							</p>
						</div>
					) : null}
					{character.status === CharacterStatus.UNDER_REVIEW ? (
						<div className="flex overflow-hidden rounded-sm bg-gradient-to-r from-blue-400 to-blue-500 font-semibold text-xs shadow-sm ring-1 ring-blue-400">
							<p className="inline-flex items-center gap-1 py-1 pr-2 pl-1 text-white">
								<ClockIcon className="size-3.5" />
								<span>Under review</span>
							</p>
						</div>
					) : null}
					{character.status === CharacterStatus.NOT_APPROVED ? (
						<div className="flex overflow-hidden rounded-sm bg-gradient-to-r from-red-400 to-red-500 font-semibold text-xs shadow-sm ring-1 ring-red-400">
							<p className="inline-flex items-center gap-1 py-1 pr-2 pl-1 text-white">
								<XIcon className="size-3.5" />
								<span>Not approved</span>
							</p>
						</div>
					) : null}
				</div>

				{character.status === CharacterStatus.PUBLISHED ? (
					<div className="absolute top-0 right-0 z-30 flex w-full items-center justify-end gap-x-2 bg-gradient-to-t from-transparent to-black pt-2 pr-2">
						<span className="text-white">{t("chatNow")}</span>
						<img alt="timple.ai" className="size-4" src="/logo-icon.svg" />
					</div>
				) : null}

				{showNsfwWarning ? (
					<div className="-translate-y-1/2 absolute inset-0 top-1/2 z-20 mx-1 inline-flex size-fit items-center gap-1 rounded-sm border border-white/30 px-1.5 py-px font-medium text-[11px] text-white/90 backdrop-brightness-90 transition-colors sm:mx-auto focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2">
						<InfoIcon className="size-3.5 shrink-0" />
						<span>{t("enableReveal")}</span>
					</div>
				) : null}

				{character.nMessages ? (
					<p className="absolute top-8 right-2 z-20 mt-2 flex shrink-0 items-center gap-1 rounded-md bg-zinc-900/90 px-1.5 py-1 font-medium text-white text-xs">
						<MessageCircleIcon className="size-3.5" />
						<span>{character.nMessages}</span>
					</p>
				) : null}

				<section className="absolute inset-0 z-10">
					<img
						alt={character.name}
						className={cx(
							"relative size-full bg-gradient-to-t bg-transparent from-transparent to-black object-cover object-top",
							(character.status === CharacterStatus.NOT_APPROVED && blurOnRejected) || showNsfwWarning ? "blur" : null,
						)}
						decoding="async"
						loading="lazy"
						src={character.avatar || "/default-avatar.png"}
					/>
				</section>
			</div>

			<div
				className={cx(
					"absolute bottom-0 flex w-full grow flex-col bg-gradient-to-b bg-transparent from-transparent to-black p-4 md:p-4",
					showNsfwWarning,
				)}
			>
				<h2 className="truncate text-left font-bold text-2xl text-zinc-100 leading-tight">
					{character.name} | {character.age}
				</h2>

				{character.creator ? (
					<p className="group/link font-medium text-[13px] text-zinc-400">
						<span>by </span>
						<span className="skiptranslate bg-gradient-to-tr from-red-500 to-orange-500 bg-clip-text font-semibold text-transparent underline-offset-4 group-hover/link:underline">
							@{character.creator.username}
						</span>
					</p>
				) : null}

				<p className="mt-4 mb-3 line-clamp-2 min-h-[auto] grow text-ellipsis text-left text-[16px] text-white">
					{character.description}
				</p>

				{character.updatedAt ? (
					<time className="text-xs text-zinc-400">
						<span>{t("enableReveal")} </span>
						<span>{timeAgo.format(new Date(character.updatedAt))}</span>
					</time>
				) : null}
			</div>
		</Comp>
	);
};
